import React from 'react';
import { Link } from 'gatsby';

const styles = {
  h1: {
    color: 'white',
    fontWeight: 300,
    fontFamily: 'Raleway,sans-serif',
    fontSize: 32,
    lineHeight: 1,
    letterSpacing: 2,
    textAlign: 'center',
  },
  h3: {
    fontWeight: 500,
    fontFamily: 'Raleway,sans-serif',
    fontSize: 22,
    lineHeight: 1.4,
    letterSpacing: 0,
  },
};

class PostListing extends React.Component {
  getPostList() {
    const postList = [];
    if (this.props.postEdges) {
      this.props.postEdges.forEach((postEdge) => {
        postList.push({
          path: postEdge.node.fields.slug,
          tags: postEdge.node.frontmatter.tags,
          cover: postEdge.node.frontmatter.cover,
          title: postEdge.node.frontmatter.title,
          date: postEdge.node.fields.date,
          excerpt: postEdge.node.excerpt,
          timeToRead: postEdge.node.timeToRead,
        });
      });
    }
    return postList;
  }

  render() {
    const postList = this.getPostList();
    return (
      <div>
        {
          /* Your post list here. */
          postList.map((post) => (
            <div
              style={{
                margin: 10,
              }}
            >
              <div>
                <Link to={post.path} key={post.title}>
                  <h3 style={styles.h3}>{post.title}</h3>
                  <div
                    style={{
                      width: '100%',
                      height: 1,
                      backgroundColor: 'darkgrey',
                      margin: 'auto',
                      marginTop: 5,
                      marginBottom: 5,
                    }}
                  />
                </Link>
              </div>
            </div>
          ))
        }
      </div>
    );
  }
}

export default PostListing;
